import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/NewsletterTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "934px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.599999999999998%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA3XAAAN1wFCKJt4AAABCElEQVQY042RQUsCcRDF/VB9g+59gk6eOhTRpaBbh5CgYCuiTkYGah6CSIwiyiRbiiyVFg+5EoS6LO1/XS0oXffXriC1atCDOcyDeW/mTQAXjuMwhFHcH/g9H/CaPqG/W5T0V1ShUTbqjDIb7L/abR/vE8y8KEjyMfv5NHuFS87kO0LhCBvxBOuxBPHTc0K7EVJZGV2YhI+SZAvF3qzd7f4I9h287WpNgfho8WzWWdjaZnFzh+DSMtMra0zMzTO7KjE2GewJj0/NcOKKe+jY9vCGWsvkvlom55ZqamhvBqnrG26fFDIPeYTVRIoecHiR5ir3iKJWMBqW/+TBbDynz46by/9/4sv1G2shujMHTg19AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png",
          "srcSet": ["/static/c9103bc33c8add5ab1fa4fa1c49c90ef/43fa5/logo-wordmark.png 250w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/c6e3d/logo-wordmark.png 500w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png 934w"],
          "sizes": "(max-width: 934px) 100vw, 934px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "work-at-coqui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#work-at-coqui",
        "aria-label": "work at coqui permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👩‍💻Work at Coqui`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`We’re hiring!`}</p>
    <p>{`An open source remote-friendly Berlin based startup founded by the creators of Mozilla’s
text-to-speech (TTS) and speech-to-text (STT) engines (over 5M downloads and 30K GitHub
stars), with the backing of top-flight investors `}<em parentName="p">{`and`}</em>{` we’re hiring!`}</p>
    <p>{`What’s not to love?`}</p>
    <p>{`We’re hiring across-the-board for a number of roles; so, there’s something for everyone:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/head-of-product"
        }}>{`Head of Product`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-full-stack-engineer"
        }}>{`Senior Full Stack Engineers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-stt-deep-learning-engineer"
        }}>{`Senior STT Deep Learning Engineer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-developer-community-manager"
        }}>{`Senior, Developer Community Managers`}</a></li>
    </ul>
    <p>{`The full list of open positions is available on our `}<a parentName="p" {...{
        "href": "/jobs"
      }}>{`jobs page`}</a>{`.`}</p>
    <p>{`We’d love to hear from you; so, if any roles pique your interest, reach out to
`}<a parentName="p" {...{
        "href": "mailto:jobs@coqui.ai"
      }}>{`jobs@coqui.ai`}</a>{`. 🐸!`}</p>
    <h3 {...{
      "id": "hello-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hello-",
        "aria-label": "hello  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Hello! 👋🐸`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`Hear 👂🏼 the newsletter!`}</p>
    <div align="center">
  <iframe width="560" height="315" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1229262295&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
    </div>
    <p>{`Or read 📗 the newsletter!`}</p>
    <p>{`Hack the Planet🔥🔥🔥 with our new speech-to-text and text-to-speech releases!`}</p>
    <p>{`On March 8th Coqui and Mozilla are teaming up to Hack the Planet! (Well, at
least part of the planet.) We’re flipping the script on machine learning
Hackathons, taking machine learning out of the equation, and catapulting
hackers directly into building real, voice-enabled products. Interested?
`}<a parentName="p" {...{
        "href": "https://schedule.mozillafestival.org/session/JVHV3M-1"
      }}>{`Sign up here`}</a>{`!`}</p>
    <p>{`As we want to make sure your hacking is cutting edge, we’re also releasing
a new version of our speech recognition engine, Coqui STT 1.3.0. It includes`}</p>
    <ul>
      <li parentName="ul">{`Initial support for Apple Silicon`}</li>
      <li parentName="ul">{`Pre-built binaries for iOS/Swift in CI`}</li>
    </ul>
    <p>{`and tons of other things that aren’t Apples🍏.`}</p>
    <p>{`The new STT release is available on `}<a parentName="p" {...{
        "href": "https://pypi.org/project/stt/"
      }}>{`PyPI`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/stt"
      }}>{`npmjs`}</a>{`, and we have pre-built binaries
and bindings for C/C++, Java, and iOS/Swift on our
`}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/STT/releases"
      }}>{`release page`}</a>{`.`}</p>
    <p>{`Speech synthesis, not to be out-done, is also releasing a new version,
Coqui TTS 0.6.0. It’s overflowing with goodness:`}</p>
    <ul>
      <li parentName="ul">{`New Vocabulary, Tokenizer, and Phonemizer APIs`}</li>
      <li parentName="ul">{`A general-purpose 👟Trainer`}</li>
      <li parentName="ul">{`A new multi-speaker, English YourTTS model with phonemes`}</li>
      <li parentName="ul">{`New Turkish and Italian models`}</li>
    </ul>
    <p>{`and those are just the headlines. The new TTS release is available on
`}<a parentName="p" {...{
        "href": "https://pypi.org/project/tts/"
      }}>{`PyPI`}</a>{`.`}</p>
    <p>{`Enjoy the newsletter! 🐸`}</p>
    <h3 {...{
      "id": "hacktheplanet-hackathon",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hacktheplanet-hackathon",
        "aria-label": "hacktheplanet hackathon permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`#`}{`HackThePlanet Hackathon`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.2%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUEBgj/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABy2yiuomrgWX/xAAZEAACAwEAAAAAAAAAAAAAAAADBAABAhP/2gAIAQEAAQUCDVWRrlzim8CK4+DQ5//EABgRAAIDAAAAAAAAAAAAAAAAAAESEBET/9oACAEDAQE/Ac2LXH//xAAYEQEBAAMAAAAAAAAAAAAAAAACAQMQEv/aAAgBAgEBPwFZaVzDr//EAB8QAAIBBAIDAAAAAAAAAAAAAAECEQADEBITIjFRUv/aAAgBAQAGPwJZ8V0tcZ2+5xs4Rh6dZFFbWskR1sBRj//EAB0QAQEAAQQDAAAAAAAAAAAAAAERIQAQMXFBUfD/2gAIAQEAAT8hCs7yWaNnJp5T13tOMGHM9Cao4xIwFjVXh+m3/9oADAMBAAIAAwAAABC3z//EABoRAQABBQAAAAAAAAAAAAAAAAEREDFBceH/2gAIAQMBAT8QSDjGOSX00//EABkRAQACAwAAAAAAAAAAAAAAAAERQRCRwf/aAAgBAgEBPxAIxm63DzH/xAAbEAACAgMBAAAAAAAAAAAAAAABETFBACFREP/aAAgBAQABPxADrBsBxmH3CtwBgZggmiLMbEeHJwRRUZSu3GVUVphGcgJOjT0Pn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/de322b714e90a339a70c24ea7d84c1bc/dbdff/HackThePlanet.jpg",
          "srcSet": ["/static/de322b714e90a339a70c24ea7d84c1bc/0988f/HackThePlanet.jpg 250w", "/static/de322b714e90a339a70c24ea7d84c1bc/d1f95/HackThePlanet.jpg 500w", "/static/de322b714e90a339a70c24ea7d84c1bc/dbdff/HackThePlanet.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`Mozilla and Coqui are teaming up again to put on a truly unique hackathon.`}</p>
    <p><a parentName="p" {...{
        "href": "https://schedule.mozillafestival.org/session/JVHV3M-1"
      }}>{`Sign up here`}</a>{`, join a team
on March 8, and get ready to make something awesome!`}</p>
    <p>{`You might remember our last hackathon in 2021, where determined machine learning
“hackers” competed to train the most accurate speech-to-text models for languages
traditionally under-served by speech technologies.`}</p>
    <p>{`This time, we’re doing things differently.`}</p>
    <p>{`We’re taking all the machine learning out of the equation, and focusing on building
real, voice-enabled products. Hackers will be pulling down pre-trained speech-to-text
and text-to-speech models, and plugging them into new, meaningful products.`}</p>
    <p>{`You don’t have to be an expert in machine learning to create amazing voice-enabled
products…that’s the whole point!`}</p>
    <p>{`Never used speech-to-text or text-to-speech before? ✨This is the perfect opportunity
to get your feet wet! ✨`}</p>
    <p>{`We can’t sit back and wait for Big Tech to make apps for the rest of the world’s
languages. Let’s Hack the Planet! During this workshop, participants will develop
products of their choice incorporating speech-to-text and text-to-speech for the
languages of their choice, using Coqui’s existing voice tech which has been
trained using data from the Common Voice project.`}</p>
    <h3 {...{
      "id": "tts-v060",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tts-v060",
        "aria-label": "tts v060 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🐸TTS v0.6.0`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQGAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAAGBAX/2gAMAwEAAhADEAAAAX1ucmRTVxhLqf/EABkQAAMBAQEAAAAAAAAAAAAAAAMEBQIBIv/aAAgBAQABBQLPOMGVXC1o/glXpVk5Vgm7z9Pax//EABwRAAICAgMAAAAAAAAAAAAAAAECACESMSJR8P/aAAgBAwEBPwFODqrDHdDXqhJ7n//EAB4RAQABAwUBAAAAAAAAAAAAAAECAAMREiFBYbHh/9oACAECAQE/AWI2pysuQxvz37RqT61//8QAIhAAAgEEAgEFAAAAAAAAAAAAAQIDABESIQQxIiNBUWJx/9oACAEBAAY/ArxZABdtPpVH52aCxWSaLd3Hi9/kURLxuRl9DkKl5gl3Enpi3RrBjksyBDlWAQde1f/EAB4QAQEAAgICAwAAAAAAAAAAAAERACExUWFxkaHB/9oACAEBAAE/IdgmzaraPZ9GQJACHkHZe8kR+FPwn7vESWgGoyvq4dQxwLZb85JjBy1n/9oADAMBAAIAAwAAABC4/wD/xAAaEQEAAgMBAAAAAAAAAAAAAAABETEAUWGB/9oACAEDAQE/EGhqpBZIXvG3wTDn/8QAGxEBAAICAwAAAAAAAAAAAAAAAREhAIExcfD/2gAIAQIBAT8Qt8xKbAwvkjpW1ETxtz//xAAdEAEBAAMBAAMBAAAAAAAAAAABEQAhMUFhcYGR/9oACAEBAAE/EOPe1WRKjODEa3JPcYFeVAEOhBKMQQrykpBca06APcRkiFCLNGH5b9YR6aKQA4EP8+cWkFIu2xdl3L+5/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg",
          "srcSet": ["/static/1e77dc6fce06f5a577ab0e4d0674e779/0988f/release.jpg 250w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/d1f95/release.jpg 500w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/erogol"
      }}>{`Eren Gölge`}</a></p>
    <p>{`v0.6.0 comes with new models and a ton of updates. Here are some of the most important ones
(Check the `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/TTS/releases/tag/v0.6.0"
      }}>{`release notes`}</a>{` for all the
details.):`}</p>
    <ul>
      <li parentName="ul">{`New Vocabulary, Tokenizer, Phonemizer APIs`}
        <ul parentName="li">
          <li parentName="ul">{`Now incorporating a new language or an external grapheme-to-phoneme (G2P) solution is
easier than ever. You just need to inherit from the base classes and implement their
functions.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Refactoring of the TTSDataset`}
        <ul parentName="li">
          <li parentName="ul">{`We split a TTSDataset into TTSDataset, PhonemeDataset, and F0Dataset to better separate
the concerns and let developers inherit as they need to create new datasets.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Factoring out the 🐸TTS trainer as a general-purpose 👟`}<a parentName="li" {...{
          "href": "https://github.com/coqui-ai/Trainer"
        }}>{`Trainer`}</a>
        <ul parentName="li">
          <li parentName="ul">{`👟Trainer is intended to be a super flexible, succinctly written, and expandable general-purpose
PyTorch model trainer. We also plan to share it among our other upcoming projects. It is very
early-stage even though it’s been used in 🐸TTS for a while by now. Let us know what you think!`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Refactoring the VITS model fixing some issues affecting the quality of the previous model`}</li>
      <li parentName="ul">{`New multi-speaker, English YourTTS model with phonemes`}
        <ul parentName="li">
          <li parentName="ul">{`Now you can get way better voice quality (with the fixes in the VITS model) and
way better pronunciation accuracy (with the use of phonemes instead of graphemes).`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Turkish Glow TTS model and HifiGAN vocoder by 👑 Fatih Akademi`}</li>
      <li parentName="ul">{`Italian Glow TTS and VITS models by 👑 `}<a parentName="li" {...{
          "href": "https://github.com/nicolalandro"
        }}>{`nicolalandro`}</a></li>
      <li parentName="ul">{`Updated versions of the earlier models based on the recent API changes`}</li>
    </ul>
    <h3 {...{
      "id": "stt-v130",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#stt-v130",
        "aria-label": "stt v130 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🐸STT v1.3.0`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQGAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAAGBAX/2gAMAwEAAhADEAAAAX1ucmRTVxhLqf/EABkQAAMBAQEAAAAAAAAAAAAAAAMEBQIBIv/aAAgBAQABBQLPOMGVXC1o/glXpVk5Vgm7z9Pax//EABwRAAICAgMAAAAAAAAAAAAAAAECACESMSJR8P/aAAgBAwEBPwFODqrDHdDXqhJ7n//EAB4RAQABAwUBAAAAAAAAAAAAAAECAAMREiFBYbHh/9oACAECAQE/AWI2pysuQxvz37RqT61//8QAIhAAAgEEAgEFAAAAAAAAAAAAAQIDABESIQQxIiNBUWJx/9oACAEBAAY/ArxZABdtPpVH52aCxWSaLd3Hi9/kURLxuRl9DkKl5gl3Enpi3RrBjksyBDlWAQde1f/EAB4QAQEAAgICAwAAAAAAAAAAAAERACExUWFxkaHB/9oACAEBAAE/IdgmzaraPZ9GQJACHkHZe8kR+FPwn7vESWgGoyvq4dQxwLZb85JjBy1n/9oADAMBAAIAAwAAABC4/wD/xAAaEQEAAgMBAAAAAAAAAAAAAAABETEAUWGB/9oACAEDAQE/EGhqpBZIXvG3wTDn/8QAGxEBAAICAwAAAAAAAAAAAAAAAREhAIExcfD/2gAIAQIBAT8Qt8xKbAwvkjpW1ETxtz//xAAdEAEBAAMBAAMBAAAAAAAAAAABEQAhMUFhcYGR/9oACAEBAAE/EOPe1WRKjODEa3JPcYFeVAEOhBKMQQrykpBca06APcRkiFCLNGH5b9YR6aKQA4EP8+cWkFIu2xdl3L+5/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg",
          "srcSet": ["/static/1e77dc6fce06f5a577ab0e4d0674e779/0988f/release.jpg 250w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/d1f95/release.jpg 500w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/reuben"
      }}>{`Reuben Morais`}</a></p>
    <p>{`STT 1.3.0 comes less than a month after the last stable release, but it is already packed
with lots of improvements. Since the last release we’ve added improvements to our
training code, our platform support, and added some new pre-built binaries. We have:`}</p>
    <ul>
      <li parentName="ul">{`Added initial support for Apple Silicon in our pre-built binaries - C/C++ pre-built
libraries are universal, language bindings will be updated soon`}</li>
      <li parentName="ul">{`Added pre-built binaries for iOS/Swift bindings in CI`}
        <ul parentName="li">
          <li parentName="ul">{`With these two changes we’re hoping to get more feedback from iOS developers on our
Swift bindings and pre-built STT frameworks - how can we best package and distribute
the bindings so that it feels native to Swift/iOS developers? If you have any feedback,
join `}<a parentName="li" {...{
              "href": "https://gitter.im/coqui-ai/STT"
            }}>{`our Gitter room`}</a>{`!`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Added new experimental APIs for loading Coqui STT models from memory buffers`}
        <ul parentName="li">
          <li parentName="ul">{`This allows loading models without writing them to disk first, which can be useful
for dynamic model loading as well as for handling packaging in mobile platforms`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Added ElectronJS 16 support`}</li>
      <li parentName="ul">{`Rewrote audio processing logic in iOS demo app`}</li>
      <li parentName="ul">{`Extended the Multilingual LibriSpeech importer to support all languages in the dataset`}
        <ul parentName="li">
          <li parentName="ul">{`Supported languages: English, German, Dutch, French, Spanish, Italian, Portuguese,
Polish`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Exposed full metadata information for decoded samples when using the `}<inlineCode parentName="li">{`coqui_stt_ctcdecoder`}</inlineCode>{`
Python package`}
        <ul parentName="li">
          <li parentName="ul">{`This allows access to the entire information returned by the decoder in training code,
meaning experimenting with new model architectures doesn’t require adapting the C++
inference library to test your changes.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Added support for training on FLAC files`}</li>
    </ul>
    <p>{`And more! In the last month we also started working on adding speaker diarization to the
Coqui STT capabilities. We have our first model up and running internally, and hope to
make it more broadly available soon.`}</p>
    <p>{`Every Coqui STT release is built in collaboration with our community members who help us
develop, diagnose and triage improvements and fixes to our packages. This month we’d like
to say a special thanks to `}<a parentName="p" {...{
        "href": "https://github.com/wasertech"
      }}>{`Danny Waser`}</a>{`, who’s been doing
lots of work on our dataset importers, and was responsible for extending our Multilingual
LibriSpeech importer to more languages. Thanks Danny!`}</p>
    <p>{`Our pre-built packages are available on PyPI via`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-console"
      }}>{`pip install stt
`}</code></pre>
    <p>{`and npmjs.com via`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-console"
      }}>{`npm install stt
`}</code></pre>
    <p>{`We also have pre-built binaries and bindings for C/C++, Java, and iOS/Swift in our GitHub
Release page. Install or update your STT integration and
`}<a parentName="p" {...{
        "href": "https://gitter.im/coqui-ai/STT"
      }}>{`let us know`}</a>{` how 1.3.0 is doing for you!`}</p>
    {
      /* markdownlint-enable line-length */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      